@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Dark mode */
  --bg-color: #1d1d1d;
  --text-color: #f8f8f2;
  --border-color: #282a36;
}
